<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <!-- terms section -->
    <section v-else class="conditions">
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <h1 v-html="$prismic.asHtml(this.terms_title)" class="conditions__title"></h1>
            <p v-html="$prismic.asHtml(this.terms_info)" class="conditions__info"></p>
          </b-col>
          <b-col cols="12" lg="5">
            <img v-lazy="this.terms_image.url" class="conditions__image img-fluid" :alt="this.terms_image.alt" />
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <div class="conditions__content" v-html="$prismic.asHtml(this.terms_content)"></div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- aside section -->
    <AsideComponent />
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'TermsPage',
  components: {
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      terms_title: null,
      terms_info: null,
      terms_content: null,
      terms_image: null
    }
  },
  methods: {
    async getTermsPageContent () {
      await this.$prismic.client.getSingle('terms')
        .then((document) => {
          this.terms_title = document.data.terms_title
          this.terms_info = document.data.terms_info
          this.terms_content = document.data.terms_content
          this.terms_image = document.data.terms_image
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getTermsPageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/_conditions.scss";
</style>
